import CryptoJS from 'crypto-js';
import qs from 'qs';
import subSites from '@/assets/contents/sub-sites.json';

export function isMobile(ua?: string) {
  if (!ua && !process.client) {
    return false;
  }
  return /(iPhone|iPad|iPod|iOS|Android)/i.test(ua || navigator.userAgent);
}

export function isBaiduSmartProgram(ua?: string) {
  if (!ua && !process.client) {
    return false;
  }
  return /(swan|baiduboxapp)/i.test(ua || navigator.userAgent);
}

export function removeEmptyField<T extends Record<string, any>>(
  data?: Record<string, any>,
  deep = false,
  emptyArray = ['', undefined, null, 'all']
) {
  if (!data) {
    return {} as T;
  }
  return Object.keys(data).reduce((res, key) => {
    if (data[key] && typeof data[key] === 'object' && !Array.isArray(data[key]) && deep) {
      res[key as keyof T] = removeEmptyField(data[key]);
    } else if (!emptyArray.includes(data[key])) {
      res[key as keyof T] = data[key];
    }
    return res;
  }, {} as T);
}

export function shuffle(arr: Array<any>) {
  let currentIndex = arr.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [arr[currentIndex], arr[randomIndex]] = [arr[randomIndex], arr[currentIndex]];
  }

  return arr;
}

export function getRandomItems(arr: Array<any>, count: number) {
  const shuffled = shuffle(arr);
  return shuffled.length < count ? [...shuffled] : shuffled.splice(0, count);
}

const aesKey = CryptoJS.enc.Utf8.parse('digitalglobalhiredigitalglobalhi');
const aesIv = CryptoJS.enc.Utf8.parse('0123456789abcdef');
const aesOptions = {
  iv: aesIv,
  mode: CryptoJS.mode.CBC,
  padding: CryptoJS.pad.Pkcs7,
};

export function getLegacyRealId(encryptedId: string): number {
  try {
    const decrypted = CryptoJS.AES.decrypt(encryptedId, aesKey, aesOptions);
    return Number(CryptoJS.enc.Utf8.stringify(decrypted));
  } catch (err) {
    return 0;
  }
}

export function getRealId(encryptedId: string, prefix?: string): number {
  try {
    const decrypted = CryptoJS.AES.decrypt(
      {
        ciphertext: CryptoJS.enc.Hex.parse(encryptedId),
      },
      aesKey,
      aesOptions
    );
    const decryptedId = CryptoJS.enc.Utf8.stringify(decrypted);
    if (decryptedId) {
      if (prefix) {
        return Number(decryptedId.replace(new RegExp(`^${prefix}_`), ''));
      }
      return Number(decryptedId);
    }
    return getLegacyRealId(encryptedId);
  } catch (err) {
    return getLegacyRealId(encryptedId);
  }
}

export function getEncryptedId(id: number | string, prefix?: string) {
  if (!id) {
    return '';
  }
  if (prefix) {
    id = `${prefix}_${id}`;
  }
  return CryptoJS.AES.encrypt(id + '', aesKey, aesOptions).ciphertext.toString();
}

export function encrypt(text: string) {
  return CryptoJS.AES.encrypt(text, aesKey, aesOptions);
}

export function decrypt(cyphered: string) {
  try {
    const decrypted = CryptoJS.AES.decrypt(cyphered, aesKey, aesOptions);
    return CryptoJS.enc.Utf8.stringify(decrypted);
  } catch (err) {
    return '';
  }
}

export function blurText(text: string) {
  return text
    .replace(/[a-zA-Z]/g, '?')
    .replace(/[^\u0000-\u00ff]/g, '?')
    .replace(/\d/g, '?');
}

export function isPersonalEmail(email: string) {
  const personalMailbox = [
    'gmail.com',
    'yahoo.com',
    'msn.com',
    'hotmail.com',
    'aol.com',
    'ask.com',
    'live.com',
    'qq.com',
    '0355.net',
    '163.com',
    '163.net',
    '263.net',
    '3721.net',
    'yeah.net',
    'googlemail.com',
    'mail.com',
    'sina.com',
    '21cn.com',
    'sohu.com',
    'yahoo.com.cn',
    'tom.com',
    'etang.com',
    'eyou.com',
    '56.com',
    'x.cn',
    'chinaren.com',
    'sogou.com',
    'citiz.com',
  ];
  return personalMailbox.findIndex(item => email.endsWith(item)) > -1;
}

export function isSpider(ua?: string) {
  if (!ua && !process.client) {
    return false;
  }
  return /(baiduspider|googlebot|bingbot|sosospider|360spider|youdaobot|jikeSpider)/i.test(ua || navigator.userAgent);
}

type IParams = 'freeTrial' | 'freeDownload' | 'unlock' | 'contactUs' | 'serviceConsulting';
type IArg = {
  targetCountry?: string;
  downloadCountryName?: string;
};
interface ILinkQuery {
  from: string;
  type: IParams;
  path: string;
  arg?: IArg;
}
export const getLeadsLink = (query: ILinkQuery) => {
  const q = qs.stringify(query);
  return `/about/free-consultation?${decodeURIComponent(q)}`;
};

interface IMetaInfo {
  title: string;
  keyWords: string;
  description?: string;
}
const STATIC_DESC =
  'DG.hire 在全球 150 个国家提供海外人力外包、海外劳务派遣、海外猎头招聘、海外员工发薪、员工签证保险办理服务，同时有 50 名法律专家帮助您解决合规安全问题，帮助企业开启海外人力布局。';
export const setTDK = (info: IMetaInfo) => {
  const { title, keyWords, description = STATIC_DESC } = info;
  useHead({
    title,
    meta: [
      {
        name: 'keywords',
        content: keyWords,
      },
      {
        name: 'description',
        content: description,
      },
    ],
  });
};

export function getSubDomain(domain: string) {
  const regex = /^(.*)\.dghire\.com(:\d{2,5})?$/;
  const match = regex.exec(domain);
  return match?.length ? match[1] : '';
}

export function getUserBrowseTrack(modelTitle: string) {
  if (!process.client) {
    return '';
  }
  const host = window.location.host;
  const subDomain = getSubDomain(host || '');
  const isSubstation = Object.keys(subSites).includes(subDomain);
  const subName = subSites[subDomain as keyof typeof subSites];

  const recordKey = '__record_list__';
  const record = localStorage.getItem(recordKey);
  const replaceIndexStr = isSubstation ? `dghire - ${subName}企业出海服务平台` : 'dghire - 全球用工与薪酬管理平台';
  const replaceOtherStr = isSubstation ? ` - dghire - ${subName}企业出海服务平台` : ' - dghire - 全球用工与薪酬管理平台';
  if (record) {
    const recordList = JSON.parse(record);
    recordList[recordList.length - 1] = `留资-${modelTitle}`;
    return recordList.map((str: string) => str.replace(replaceOtherStr, '').replace(replaceIndexStr, '首页').replace(/ /, '')).join(' -> ');
  }
  return '';
}

export function replaceInTpl(tpl: string, context: Record<string, string>) {
  return tpl.replace(/\$\{([\s]*[^;\s{]+[\s]*)\}/g, (_, match) => {
    if (Object.prototype.hasOwnProperty.call(context, match)) {
      return context[match];
    }
    return '';
  });
}
